import React from "react";
import "./workTime.scss";
import {Title} from "../../elements/Title/title";

export const WorkTime = props => {
    const data = props.data;
    const title = props.title;
    let dataFormated = [];
    let isShow = false;
    if (Object.entries(data).length > 3) {
        isShow = true;
        for (const [key, value] of Object.entries(data)) {
            let time = "";
            let i = 0;
            let separeate = "";

            value.forEach(element => {
                if (i > 0) {separeate = "; "}
                time = time + separeate + element;
                i++;
            });

            let newItem = {
                "day" : key,
                "work_time" : time
            };
            dataFormated.push(newItem)
        }
    }

    return (
        <div>
            {isShow && <Title tag={`h2`} size20 content={title} />}

            {isShow &&
                <ul className={"m-work-time"}>
                    {dataFormated.map((value, index) => (
                        <li key={index}>
                            <span className={"m-work-time--name"}>{value.day}:</span> {value.work_time}
                        </li>
                    ))}
                </ul>
            }
        </div>
    );
};

export default WorkTime;