import React from "react"

const financialService = ({ jsontld }) => {
    const name = jsontld.data.name;
    const phone = jsontld.data.phone;
    const email = jsontld.data.email;
    const address = jsontld.data.address;
    const url = jsontld.data.url;
    // const ratingValue = jsontld.data.ratingValue;
    // const reviewCount = jsontld.data.reviewCount;
    const image = jsontld.data.image;
    const parentName = jsontld.parent.name;
    const parentImage = jsontld.parent.image;
    // const parentAddress = jsontld.parent.address;
    // const parentPhone = jsontld.parent.phone;

    const ldjson = {
        "@context": "http://schema.org",
        "@type": "FinancialService",
        "name": name,
        "telephone": phone,
        "email": email,
        "address": address,
        "url": url,
        "branchOf": {
            "@type": "BankOrCreditUnion",
            "name": parentName,
            "image": parentImage,
            // "address": parentAddress,
            // "telephone": parentPhone
        },
        // "aggregateRating": {
        //     "@type": "AggregateRating",
        //     "ratingValue": ratingValue,
        //     "reviewCount": reviewCount
        // },
        "image": image
    };


    return (
        <script
            type="application/ld+json"
            dangerouslySetInnerHTML={{ __html: JSON.stringify(ldjson) }}
        />
    )
}

export default financialService