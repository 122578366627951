import React, { Component } from "react";
import Layout from "../../components/layout/layout.js";
import Module from "../../components/structure/Module/module.js";
import Wrapper from "../../components/structure/Wrapper/wrapper.js";
import Column from "../../components/structure/Column/column.js";
import Box from "../../components/modules/Box/box.js";
import Seo from "../../components/seo/seo.js";
import {Title} from "../../components/elements/Title/title";
import {Breadcrumbs} from "../../components/modules/Breadcrumbs/breadcrumbs";
import {Rating} from "../../components/modules/Rating/rating";
import RichText from "../../components/modules/RichText/richText.js";
import WorkTime from "../../components/modules/WorkTime/workTime.js";
import LeafletMap from "../../components/modules/Map/leafletMap.js";
import Faq from "../../components/modules/Faq/faq";
import Image from "../../components/modules/Image/image.js";
import WidgetBanks from "../../components/modules/WidgetBanks/widgetBanks.js";
import {Table} from "../../components/modules/Table/table";
import FinancialService from "../../components/seo/ld-json/financialService";
import JsonBreadcrumbs from "../../components/seo/ld-json/jsonBreadcrumbs.js";
import OtherProduct from "../../components/modules/OtherProduct/otherProduct";
import {CardsOther} from "../../components/modules/CardsOther/cardsOther";
import AdsenseManager from '../../components/modules/Adsense/AdsenseManager'
import {Opinions} from "../../components/modules/Opinions/opinions";

class Office extends Component {

    render() {
        const dataOffice = this.props.pageContext.data;
        const seo = this.props.pageContext.seo;
        const textStatic = this.props.pageContext.textStatic;
        const rating = true;//this.props.pageContext.rating || false;
        const country_code = seo.country_code;
        const mainTitle = seo.mainTitle || false;
        const mainText = this.props.pageContext.mainText || false;
        const subTitle = seo.subTitle || false;
        const asks = this.props.pageContext.asks || false;
        const otherCard = this.props.pageContext.otherCard;
        const otherAccount = this.props.pageContext.otherAccount;
        const h2_other_cards = this.props.pageContext.h2_other_cards;
        const h2_other_bank_account = this.props.pageContext.h2_other_bank_account;
        const productMeta = this.props.pageContext.productMeta;
        const adsense = this.props.pageContext.adsense;

        const dataMap = [
            {
                "name": dataOffice.bank_name,
                "latitude": dataOffice.latitude,
                "longitude": dataOffice.longitude,
            }
        ];

        const jsontld = {
            "data": {
                "name": `${mainTitle}`,
                "phone": `${dataOffice.phone}`,
                "email": `${dataOffice.email}`,
                "address": `${dataOffice.google_street}`,
                "url": `${dataOffice.url_home}`,
                "image": `${seo.image}`,
            },
            "parent": {
                "name": `${dataOffice.bank_name}`,
                "image": `${seo.image}`,
            }
        };

        const tableInfo = [
            [textStatic.office_main_info_name, mainTitle],
            [textStatic.office_main_info_address, dataOffice.google_address],
            [textStatic.office_main_info_phone, dataOffice.phone],
            [textStatic.office_main_info_email, dataOffice.email],
            [textStatic.office_main_info_url_home, dataOffice.url_home],
            [textStatic.office_main_info_url_cabinet, dataOffice.url_cabinet]
        ];

        return (
            <Layout>
                <Seo lang={seo.lang} title={seo.title} url={seo.url} image={seo.image} description={seo.description} financialService={jsontld}  robotsIndex={seo.robotsIndex} robotsFollow={seo.robotsFollow}/>
                <Module bgPr>
                    <Wrapper white>
                        <Breadcrumbs content={dataOffice.breadcrumbs} host={seo.host}/>
                        <JsonBreadcrumbs data={dataOffice.breadcrumbs} host={seo.host}/>

                        <Column row>
                            <Column col="fifth" colSm="half" colXs="full">
                                <Image filename={dataOffice.bank_image} alt={dataOffice.bank_name} />
                            </Column>
                            <Column col="fifth-complete" colSm="half" colXs="full">
                                <Title tag={`h1`} size35 content={mainTitle}/>
                            </Column>
                        </Column>


                        <Column row>
                            {rating && (
                                <Column colXs="full" col="full">
                                    <Rating
                                        textStatic={textStatic}
                                        country_code={country_code}
                                        object_name={"office"}
                                        object_id={dataOffice.id}
                                        parent_id={dataOffice.bank_id}
                                        jsontld_parent={jsontld}
                                        jsontld_parent_type="FinancialService"
                                    />
                                </Column>
                            )}
                            <Column col="forth-complete" colSm="full">
                                <Box bgPr borderTop>
                                    <Title tag={`h2`} size20 content={subTitle} />

                                    <Table info firstColStrong dataBody={tableInfo} />

                                    <AdsenseManager adsense={adsense}/>

                                    <RichText content={mainText} />

                                    <WorkTime data={dataOffice.work_time} title={textStatic.office_subtitle_work_time}/>

                                    <AdsenseManager adsense={adsense}/>

                                    <Title tag={`h2`} size20 content={textStatic.office_subtitle_map} />

                                    <LeafletMap dataMap={dataMap} textStatic={textStatic} zoom={15}/>

                                    <Faq asks={asks} title={textStatic.faq_title}/>

                                    {otherCard && (
                                        <CardsOther dataCards={otherCard} title={h2_other_cards} productMeta={productMeta} textStatic={textStatic}/>
                                    )}

                                    <AdsenseManager adsense={adsense}/>

                                    {otherAccount && (
                                        <OtherProduct type={"account"} className={`marg-t30`} title={h2_other_bank_account} products={otherAccount}/>
                                    )}

                                    <Title tag={`h2`} size20 content={textStatic.office_subtitle_map_other} />

                                    <LeafletMap dataMap={dataOffice.closet_offices} textStatic={textStatic} zoom={15}/>

                                    <Opinions
                                        textStatic={textStatic}
                                        seo={seo}
                                        object_name={"office"}
                                        country_code={country_code}
                                        object_id={dataOffice.id}
                                    />
                                </Box>
                            </Column>
                            <Column col="forth" colSm="full">
                                <Box bgPr>
                                    <WidgetBanks />
                                </Box>
                            </Column>
                        </Column>
                    </Wrapper>
                </Module>
                <FinancialService jsontld={jsontld}/>
            </Layout>
        );

    }
}

export default Office;
